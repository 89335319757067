import { camelCase } from 'camel-case'

import Mixpanel from '@/plugins/mixpanel'

// we'll use AbortController so we can cancel a page load if the user rapidly
//  pages through data
const controllerMap = {}

const compareByKey = (key, a, b) => {

  // @NOTE: camelCase() conveniently removes the "-" before "reverse" sort
  //  terms
  const camelCaseKey = camelCase(key)

  if (!Object.hasOwnProperty.call(a, camelCaseKey) || !Object.hasOwnProperty.call(b, camelCaseKey)) {
    return 0
  }

  const aValue = a[camelCaseKey].toString()
  const bValue = b[camelCaseKey].toString()

  // make sure to apply "reverse" sorting if the sort term begins with
  //  a hyphen
  if (aValue > bValue) return key.startsWith('-') ? -1 : 1
  if (aValue < bValue) return key.startsWith('-') ? 1 : -1

  return 0

}

export default {
  RESET_PAGINATION({ state, commit, dispatch }, { listName, filterFormName = null }) {

    const list = state[listName]

    const formName = filterFormName || list.filterFormName

    if (formName) {
      commit('forms/RESET_FORM', formName, { root: true })
    }

    return dispatch('APPLY_FILTER_FORM', { listName })

  },
  GET_ALL_ITEMS({ state, rootState }, { listName }) {
    const list = state[listName]
    return rootState.api[list.apiType]
      .get(list.apiEndpoint, { params: { sortBy: 'inserted_at', size: 2 ** 32 } })
  },
  APPLY_FILTER_FORM({ state, dispatch }, { listName, filterFormName = null }) {

    const list = state[listName]

    const formName = filterFormName || list.filterFormName

    if (!formName) return Promise.resolve()

    return dispatch('forms/GET_FORM_DATA', formName, { root: true })
      .then((formData) => {

        const { query } = formData

        delete formData.query

        return dispatch('LOAD_PAGE', {
          filters: formData,
          pageNumber: 1,
          listName,
          query,
        })

      })

  },
  LOAD_PAGE(context, newData) {

    // required
    const { listName } = newData

    // optional
    //
    // @NOTE: silent = true will disable the isLoading toggle and squlech toasts
    const { pageNumber, pageSize, filters, query, silent = false } = newData

    const { commit, dispatch, rootState, state } = context

    const list = state[listName]

    if (Object.hasOwnProperty.call(newData, 'query')) commit('SET_QUERY', { listName, query })
    if (Object.hasOwnProperty.call(newData, 'filters')) commit('SET_FILTERS', { listName, filters })

    if (Object.hasOwnProperty.call(newData, 'pageSize')) commit('SET_PAGE_SIZE', { listName, pageSize })
    if (Object.hasOwnProperty.call(newData, 'pageNumber')) commit('SET_CURRENT_PAGE_NUMBER', { listName, pageNumber })

    const mergedFilters = Object.assign({}, list.filters)

    Object.keys(list.persistantFilters)
      .forEach((filterKey) => {
        mergedFilters[filterKey] = list.persistantFilters[filterKey].concat(mergedFilters[filterKey] || [])
      })

    const params = {
      q: list.query,
      ...mergedFilters,

      sortBy: list.sortBy,
      size: list.pageSize,
      page: list.currentPageNumber,
    }

    if (!list.query) delete params.q

    // @NOTE: this can be calculated now, but it should only be updated in the
    //  store AFTER the API request has been made
    const hasFiltersOrQueryApplied = !!list.query || Object.keys(mergedFilters)
      .some((filterKey) => {
        return (
          mergedFilters[filterKey].length !== 0

          // ignore auto applied date filters (based on the user's subcription's
          // maxEventsTimeframe)
          && !filterKey.match(/\[after|before\]$/)
        )
      })

    const newController = new AbortController()
    const existingController = controllerMap[listName]

    if (existingController) {
      existingController.abort()
    }

    controllerMap[listName] = newController

    if (!silent) commit('SET_IS_LOADING', { listName, isLoading: true })

    return rootState.api[list.apiType].get(list.apiEndpoint, { params, signal: newController.signal })
      .then((response) => {

        const hasEventsOutsideHistory = response.headers['x-events-outside-history'] === 'true'
        const totalItems = Number.parseInt(response.headers['x-total-count'], 10) || 0
        const totalPages = Math.ceil(totalItems / list.pageSize)

        const currentPage = response.data
          .map((item) => {
            return list.itemMapper(item, context)
          })

          // while polling, sometimes records can shift around even when new
          //  records are not added. this is due to the database having
          //  identical timestamps for multiple records... for whatever reason
          //  the database doesn't have consistent ordering when timestamps are
          //  identical (which can happen when you have the same trigger
          //  connected to two different actions)
          //
          // so to fix it, if the list sorting finds two identical sort values,
          //  we can attempt to sort by id next, and lastly simply preserve the
          //  order returned by the API
          //
          // @TODO: however, this whole sorting block can probably be removed
          //  when the socket stuff is in place and polling is removed (or when
          //  multiple "sort" values are supported by the API (see DISPATCH-537
          //  in Jira))
          .sort((a, b) => {

            const comparisonByListSort = compareByKey(list.sortBy, a, b)
            const comparisonById = compareByKey('id', a, b)

            return (comparisonByListSort !== 0)
              ? comparisonByListSort
              : comparisonById

          })

        // if currentPageNumber is out-of-bounds, load the last page instead
        if (list.currentPageNumber > totalPages && totalItems !== 0) {
          return dispatch('LOAD_PAGE', { listName, pageNumber: totalPages, pageSize })
        }

        commit('SET_TOTAL_ITEMS', { listName, totalItems })
        commit('SET_TOTAL_PAGES', { listName, totalPages })
        commit('SET_CURRENT_PAGE', { listName, currentPage })
        commit('SET_HAS_EVENTS_OUTSIDE_HISTORY', { listName, hasEventsOutsideHistory })
        commit('SET_HAS_FILTERS_OR_QUERY_APPLIED', { listName, hasFiltersOrQueryApplied })

        if (listName === 'userPatches' && !hasFiltersOrQueryApplied) {
          Mixpanel.onReady((mixpanel) => {
            mixpanel.register({
              totalPatches: totalItems,
            })
          })
        }

        return null

      })
      .catch((error) => {

        if (error.name === 'CanceledError') return null
        if (error.response && error.response.status === 403) return null

        let errorMessage = null

        if (!error.response || !error.response.data) {
          errorMessage = rootState.api.dispatchAPIErrors.unknown_error

        } else if (Array.isArray(error.response.data)) {
          errorMessage = error.response.data[0]

        } else {
          errorMessage = error.response.data
        }

        if (silent) throw error

        return dispatch('toast/CREATE_TOAST', { text: errorMessage, type: 'error' }, { root: true })
          .finally(() => {
            throw error
          })

      })
      .finally(() => {
        delete controllerMap[listName]
        commit('SET_IS_LOADING', { listName, isLoading: false })
        commit('SET_HAS_FILTERS_OR_QUERY_APPLIED', { listName, hasFiltersOrQueryApplied })
      })
  },
  UPDATE_ITEM_IF_IN_CURRENT_PAGE(context, { listName, updatedItem }) {

    const { commit, state } = context

    commit('SET_ITEM_IN_CURRENT_PAGE', {
      listName,
      newItem: state[listName].itemMapper(updatedItem, context),
    })

  },
}
